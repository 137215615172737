<template>
  <div class="pay">
    <BaseHeaderBar title="Kode pembayaran" />
    <div class="content">
      <div class="pay-info">
        <p class="title">Jumlah pembayaran</p>
        <p class="amount">Rp{{ payInfo.padryAmount }}</p>
        <div class="pay-info-list">
          <div>
            <p class="label">Nama Bank</p>
            <p class="value">{{ payInfo.padryChannel }}</p>
          </div>
          <div>
            <p class="label">Metode pembayaran</p>
            <p class="value">{{ payInfo.padryWay }}</p>
          </div>
          <div>
            <p class="label">Masa berlaku</p>
            <p class="value">{{ payInfo.lodrseTime }}</p>
          </div>
          <div>
            <p class="label">Kode pembayaran</p>
            <p class="value">{{ payInfo.padrymentCode }}</p>
          </div>
        </div>
      </div>
      <van-tabs class="extra-info" v-model="active" animated swipeable :ellipsis="false" color="#1C0A1B" title-active-color="#E92326" title-inactive-color="#333">
        <van-tab title="Hal yang perlu diperhatikan">
          <div class="tab-content">
            <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">{{ item }}</p>
          </div>
        </van-tab>
        <van-tab title="Cara Pembayaran">
          <div class="tab-content">
            <p v-for="(item, index) in payInfo.redrpaymentProcess[0].step" :key="index">{{ item }}</p>
          </div>
        </van-tab>
      </van-tabs>
      <button type="button" class="paid-btn" @click="onPaid">Saya sudah melakukan pembayaran</button>
    </div>
    <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
      <img class="repayment-success-icon" src="@/assets/img/repayment-success-icon.png" />
      <p class="tip">Pembayaran berhasil！</p>
      <button v-if="payInfo.padryCodeid" type="button" class="ok-btn" @click="onConfirm">ok</button>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'

Vue.use(Tab)
Vue.use(Tabs)

export default {
  name: 'Pay',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      active: 0
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">
.pay {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  background: #FFF url(../assets/img/pay-bg.png) no-repeat;
  background-size: 750px 638px;
}

.pay-info {
  width: 720px;
  margin: 236px auto 0;
  border-radius: 35px;
  background-color: #1C0A1B;
}

.title {
  line-height: 42px;
  padding-top: 30px;
  font-size: 30px;
  color: #FFF;
  text-align: center;
}

.amount {
  line-height: 78px;
  margin-top: 24px;
  font-size: 56px;
  color: #FFF;
  text-align: center;
}

.pay-info-list {
  padding: 30px 65px 25px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 33px;

    &:nth-child(n+2) {
      margin-top: 68px;
    }

    p {
      color: #FFF;
    }

    .label {
      font-size: 24px;
    }

    .value {
      font-size: 26px;
    }
  }
}

.extra-info {
  margin: 24px 30px;

  /deep/ .van-tab__text {
    font-size: 26px;
  }
}

.tab-content {
  height: 374px;
  padding: 24px 42px 0;
  overflow-y: scroll;

  p {
    line-height: 37px;
    font-size: 26px;
    color: #666;

    &:nth-child(n+2) {
      margin-top: 40px;
    }
  }
}

.paid-btn {
  .submit-btn;
  width: 690px;
  margin-top: 101px;
  margin-bottom: 54px;
}

.repayment-success-icon {
  width: 349px;
  height: 175px;
  margin: 20px auto 0;
}

.tip {
  line-height: 42px;
  margin-top: 70px;
  font-size: 30px;
  font-weight: 600;
  color: #1C0A1B;
  text-align: center;
}

.ok-btn {
  .submit-btn;
  width: 629px;
  margin-top: 80px;
  margin-bottom: 24px;
}
</style>
